.app-probation-completed-modal {
	min-width: 300px;
	padding: 10px;
	display: flex;

	.probation-completed-modal {
		width: 100%;
		max-width: 560px;
		margin: auto;
		padding: 50px;
		border-radius: 8px;
		background-color: $c-white;

		&__header {
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			color: $c-dark-grey;
		}

		&__options {
			margin: 20px 0;
			display: flex;
		}

		&__radio {
			cursor: pointer;
			flex: 1;
			display: flex;
			align-items: center;

			&--active {
				.probation-completed-modal__dot {
					&:before {
						content: "";
						width: 12.5px;
						height: 12.5px;
						border-radius: 100px;
						background-color: $c-black;
						display: block;
					}
				}
			}
		}

		&__dot {
			width: 25px;
			height: 25px;
			margin: 0 10px 0 0;
			display: block;
			border-radius: 100%;
			border: 1px solid $c-black;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__label {
			margin: 0;
			color: $c-primary;
			font-weight: $text-weight-semibold;
		}

		&__button-container {
			margin: 25px 0 0;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: center;

			&--cancel {
				border: 0;

				.app-button__label {
					color: $c-primary;
				}
			}
		}

		&__button {
			margin-top: 25px;
			border: 0;

			.app-button__label {
				color: $c-primary;
			}
		}
	}
}
