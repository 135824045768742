.page-leaves-approval {
	height: 100%;

	.leaves-approval {
		height: 100%;
		display: flex;
		flex-direction: column;

		&__results {
			padding: 10px 0;
			margin: 10px 30px;
			border-bottom: 1px dashed $c-disabled;
		}

		&__text {
			margin: 0;
			font-weight: $text-weight-semibold;
		}

		&__empty-state {
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.app-empty-data {
				height: auto;
			}
		}

		.app-table {
			//
			.table {
				//
				&__exclamation {
					width: 35px;
					height: 35px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}

.app-table-menu {
	&__item {
		padding: 0 10px;
		width: 100%;
		display: flex;
		align-items: center;
	}

	&__title {
		margin: 0;
		padding: 10px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-normal;
	}

	&__text {
		margin: 0;
		color: $c-dark-grey;
		font-size: $text-size-s;
	}
}
