.app-amendment-status-modal {
	min-width: 300px;

	.amendment-status-modal {
		width: 100%;
		height: 100%;
		max-width: 560px;
		margin-left: auto;
		background-color: $c-white;

		&__main {
			width: 100%;
			height: 100%;
			padding: 25px 0;

			@include min-width($scr-xs) {
				display: flex;
				flex-direction: column;
			}

			@include max-width($scr-xs) {
				overflow-y: scroll;
				scrollbar-width: none;
				-ms-overflow-style: none;

				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}
			}
		}

		&__header {
			padding: 0 45px 15px;
		}

		&__name {
			margin: 0 0 15px;
			color: $c-black;
			font-weight: $text-weight-semibold;
		}

		&__ref-no {
			margin: 10px 0 5px;
			color: $c-primary;
			font-weight: $text-weight-semibold;
		}

		&__date {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__label {
			margin: 5px 0;
			color: $c-light-grey;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__value {
			margin: 0;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__close-button {
			width: 30px;
			height: 30px;
			margin: 0 15px 15px auto;
			border: 0;
			cursor: pointer;
			background-color: transparent;
			display: block;
		}

		&__info {
			padding: 0 45px;
			margin: 0 0 25px;
			display: flex;
			align-items: stretch;
			gap: 10px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}
		}

		&__box {
			flex: 1;
			padding: 20px 12.5px;
			background-color: #f8f8f8;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		&__title {
			margin: 0;
			font-weight: $text-weight-semibold;
		}

		&__text {
			margin: 5px 0 0;
			color: $c-light-grey;
			word-wrap: break-word;
			font-size: $text-size-xs;
		}

		&__body {
			height: 100%;
			padding: 15px 45px;
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			@include max-width($scr-xs) {
				height: auto;
				overflow: hidden;
				padding: 25px 35px;
			}
		}

		&__list {
			margin: 15px 0;
			padding: 0 0 15px;
			border-bottom: 1px dashed $c-disabled;
			gap: 15px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}

		&__item {
			width: calc(50% - 15px);
			list-style-type: none;
		}

		&__table {
			margin-bottom: 15px;
			padding-bottom: 15px;
			border-bottom: 1px dashed $c-disabled;

			&--files {
				margin-top: 15px;
				border-bottom: 0;
			}
		}

		&__table-header {
			width: 100%;
			height: 50px;
			margin: 10px 0 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__table-body {
			width: 100%;
			margin: 0 0 10px;
			background-color: #f8f8f8;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__table-title {
			margin: 0;
			padding: 15px;
			color: $c-dark-grey;
			word-break: break-word;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
			flex: 1;
		}

		&__table-file-size {
			margin: 0;
			padding: 15px;
			text-align: right;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
			flex: 1;
		}

		&__table-label {
			margin: 0;
			padding: 15px;
			color: $c-dark-grey;
			word-break: break-word;
			font-size: $text-size-xs;
			flex: 1;
		}

		&__table-value {
			margin: 0;
			padding: 15px;
			color: $c-primary;
			word-break: break-word;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
			flex: 1;
		}

		&__table-link {
			margin: 0;
			padding: 15px;
			color: $c-blue;
			cursor: pointer;
			word-break: break-word;
			font-size: $text-size-xs;
			flex: 1;
		}

		&__table-upload {
			margin: 0;
			padding: 15px;
			color: $c-teal;
			word-break: break-word;
			font-size: $text-size-xs;
			cursor: pointer;
			display: flex;
			align-items: center;

			svg {
				margin: 0 5px;
				path {
					stroke: $c-teal;
				}
			}
		}

		&__table-header-cell {
			position: relative;
			height: 100%;
			flex: 1;
			display: flex;
			align-items: center;
		}

		&__table-body-cell {
			position: relative;
			flex: 1;
			display: flex;
			align-items: center;

			.amendment-status-modal__table-file-size {
				font-weight: $text-weight-regular;
			}
		}
	}
}
