.app-leave-modal {
	min-width: 300px;

	.leave-modal {
		width: 100%;
		height: 100%;
		max-width: 560px;
		margin-left: auto;
		background-color: $c-white;

		&__main {
			width: 100%;
			height: 100%;
			padding: 25px 0;

			@include max-width($scr-xs) {
				overflow-y: scroll;
				scrollbar-width: none;
				-ms-overflow-style: none;

				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}
			}
		}

		&__header {
			padding: 0 45px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}
		}

		&__profile {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__close-button {
			width: 30px;
			height: 30px;
			margin: 0 15px 15px auto;
			border: 0;
			cursor: pointer;
			background-color: transparent;
			display: block;
		}

		.info {
			padding: 0 45px;
			margin: 0 0 25px;
			display: flex;
			align-items: stretch;
			gap: 10px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}

			&__box {
				flex: 1;
				padding: 20px 12.5px;
				background-color: #f8f8f8;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}
		}

		&__body {
			height: calc(100% - 250px);
			padding: 25px 45px;
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			@include max-width($scr-xs) {
				height: auto;
				overflow: hidden;
				padding: 25px 35px;
			}
		}

		&__container {
		}

		&__title {
			margin: 15px 0;
			font-weight: $text-weight-semibold;
		}

		&__wrapper {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}

		&__info {
			width: calc(50% - 7.5px);
			margin: 0 0 15px;

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__label {
			margin: 0 0 5px;
			color: $c-light-grey;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__value {
			margin: 0;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__link {
			color: $c-blue;
			font-size: $text-size-s;
			display: flex;
			align-items: center;
		}

		&__text {
			margin: 5px 0 0;
			color: $c-light-grey;
			word-wrap: break-word;
			font-size: $text-size-xs;
		}
	}
}
