.page-coverages {
	height: 100%;
	min-height: 550px;

	.coverages {
		height: 100%;
		display: flex;
		flex-direction: column;

		&__results {
			padding: 10px 0;
			margin: 10px 30px;
			border-bottom: 1px dashed $c-disabled;
		}

		&__text {
			margin: 0;
			font-weight: $text-weight-semibold;
		}

		&__empty-state {
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.app-empty-data {
				height: auto;
			}
		}

		.app-table {
			//
			.table {
				&__insurance {
					color: $c-dark-grey;
					word-break: break-word;
					font-size: $text-size-s;
					display: flex;
					align-items: center;

					&--not-eligible {
						&::after {
							width: 15px;
							height: 15px;
							margin: 0 5px;
							content: "";
							display: block;
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;
							background-image: url("../../../assets/images/exclamation-icon.svg");
							cursor: pointer;
						}
					}
				}

				&__td {
					.app-select-input {
						max-width: 150px;

						&__label {
							display: none;
						}

						.MuiInputBase-root {
							padding: 8px;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						.MuiSelect-select {
							padding: 0;
							color: $c-dark-grey;
							font-size: $text-size-s;
							font-weight: $text-weight-regular;
						}

						.MuiOutlinedInput-notchedOutline {
							border-radius: 10px;
							border: 1px solid $c-light-grey !important;
						}

						.MuiFormHelperText-root {
							display: none;
						}
					}
				}
			}
		}

		&__plans {
			padding: 20px 25px 35px;
			gap: 15px;
			display: flex;
			flex-wrap: wrap;
		}

		&__plan {
			border-radius: 8px;
			display: flex;
			align-items: center;

			&--premier {
				border: 1px solid #d2a741;
			}

			&--regular-plus {
				border: 1px solid #afafaf;
			}

			&--regular {
				border: 1px solid #c1a48a;
			}
		}

		&__wrapper {
			padding: 12.5px 20px;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
			display: flex;
			align-items: center;

			&--premier {
				background-color: #d2a741;
			}

			&--regular-plus {
				background-color: #afafaf;
			}

			&--regular {
				background-color: #c1a48a;
			}

			.insurance-plan {
				flex-shrink: 0;
			}
		}

		&__title {
			margin: 0 5px;
			color: $c-white;
			white-space: nowrap;
			font-size: $text-size-xs;
		}

		&__quantity {
			margin: 0;
			padding: 10px 20px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__filter {
			padding: 0 35px 20px;
			display: flex;
		}

		&__filter-text {
			margin: 0 20px 0 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__checkboxs {
			gap: 10px 20px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.app-checkbox {
				margin: 0 0 10px;
				&__box {
					border: 1px solid $c-light-grey;
				}
			}
		}

		.app-calendar-input {
			max-width: 150px;
			padding: 0 8px;
			border-radius: 8px;
			border: 1px solid $c-light-grey;

			.MuiInputBase-input {
				&::placeholder {
					font-size: $text-size-s;
				}
			}

			.MuiOutlinedInput-notchedOutline {
				border-bottom: 0 !important;
			}
		}

		&__label {
			margin: 0;
			padding: 5px 10px;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			border-radius: 100px;
			white-space: nowrap;

			&--premier {
				color: #d2a741;
				border: 1px solid #d2a741;
				background-color: #fff9ec;
			}

			&--regular-plus {
				color: #afafaf;
				border: 1px solid #afafaf;
				background-color: #f4f4f4;
			}

			&--regular {
				color: #c1a48a;
				border: 1px solid #c1a48a;
				background-color: #f9f2ec;
			}
		}
	}
}
