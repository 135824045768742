.page-select-claim-type {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px 15px;
	}

	.select-claim-type {
		padding-bottom: 25px;

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 10px 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__card {
			padding: 20px;
			margin: 25px 0;
			border-radius: 10px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(182, 189, 194, 0.15);
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			gap: 15px;
		}

		.app-checkbox {
			width: calc(50% - 15px);
			margin: 0 0 15px;
			justify-content: flex-start;

			@include max-width($scr-md) {
				width: 100%;
			}

			&--active {
				.app-checkbox__box {
					background-color: $c-teal;
				}
			}

			&__box {
				border: 1px solid $c-teal;
			}

			&__label {
				text-align: left;
				color: $c-dark-grey;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin-left: auto;
			gap: 15px;
			display: flex;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
