.app-page {
	height: 100%;
}

.app-advance-search-form {
	.advance-form {
		//
		&__inputs {
			gap: 15px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.app-input,
			.app-calendar-input,
			.app-select-input {
				width: calc(50% - 7.5px);

				@include max-width($scr-sm) {
					width: 100%;
				}
			}
		}

		&__button-container {
			padding: 20px 0 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button {
				width: auto;
				margin: 0 10px;
				padding: 10px 20px;

				&--outline {
					border: 0;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}

.app-table-menu {
	.MuiMenu-paper {
		border-radius: 8px;
		box-shadow: 0px 4px 12px 0px rgba(224, 229, 234, 0.6);

		.MuiButtonBase-root {
			padding: 10px 45px 10px 15px;
			color: $c-primary;
			font-size: $text-size-s;
		}

		.app-table-menu__remove {
			color: $c-red;
		}
	}

	.app-checkbox {
		margin: 5px 0;

		&__label {
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
		}
	}

	&__icon {
		width: 20px;
		height: 20px;
		margin: 0 8px;
	}
}

.app-coverages-menu {
	&__icon {
		width: 20px;
		height: 20px;
		margin: 0 10px 0 0;
	}

	.MuiPaper-root {
		.MuiList-root {
			.MuiButtonBase-root {
				color: $c-red;
				font-size: $text-size-xs;
				background-color: $c-white;
			}
		}
	}
}
