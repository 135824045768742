.page-policy {
	height: 100%;

	.policy {
		height: 100%;
		display: flex;
		flex-direction: column;

		&__results {
			padding: 10px 0;
			margin: 10px 30px;
			border-bottom: 1px dashed $c-disabled;
		}

		&__text {
			margin: 0;
			font-weight: $text-weight-semibold;
		}

		&__empty-state {
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.app-empty-data {
				height: auto;
			}
		}
	}
}
