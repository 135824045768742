.app-unpaid-debit-note-modal {
	margin: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	.unpaid-debit-note {
		width: 100%;
		max-width: 560px;
		border-radius: 10px;
		background-color: $c-white;
		outline: none;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__header {
			padding: 50px 35px 0;
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			color: $c-dark-grey;
		}

		&__sub-header {
			padding: 0 35px 15px;
			color: $c-black;
			background-color: $c-white;
			font-weight: $text-weight-normal;
			display: flex;
			justify-content: flex-end;

			.app-checkbox {
				margin: 0 0 0 10px;

				&__box {
					border: 1px solid $c-light-grey;
				}

				&__label {
					display: none;
				}
			}
		}

		&__list {
			margin: 0;
			padding: 0 35px;
			max-height: 300px;
			overflow-y: scroll;
		}

		&__item {
			list-style: none;
			display: flex;
			justify-content: space-between;

			.app-checkbox {
				margin: 10px 0 0;
				align-items: flex-start;

				&__box {
					border: 1px solid $c-light-grey;
				}

				&__label {
					display: none;
				}
			}
		}

		&__content {
			&:first-child {
				flex: 1;
			}
		}

		&__month {
			padding: 15px 0 0;
			margin: 15px 0 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			border-top: 1px dashed $c-disabled;
		}

		&__invoice-no {
			margin: 10px 0 5px;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__date {
			margin: 0;
			color: $c-light-grey;
			font-size: $text-size-s;
		}

		&__amount {
			margin: 10px 10px 5px;
			color: $c-black;
			font-size: $text-size-s;
		}

		&__premium-amount {
			color: $c-black;
			font-weight: $text-weight-semibold;
		}

		&__footer {
			margin: 25px 35px 0;
			padding: 25px 0 0;
			border-top: 1px dashed $c-disabled;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__loading {
			min-height: 250px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 70px;
				height: 70px;

				path {
					stroke: #eaeaea;
					stroke-dasharray: 500;
					animation: loading 5s linear infinite;
				}
			}
		}

		&__button-container {
			margin: 25px 0 0;
			padding: 0 35px 50px;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button {
				&--outline {
					border: 0;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
