.app-side-nav {
	width: $side-nav-width;
	min-height: 100vh;
	transition: $transition;
	flex-shrink: 0;

	@include max-width($scr-sm) {
		position: fixed;
		width: 0;
		margin-top: 50px;
		z-index: 1299;
		background-color: $c-primary;
	}

	.side-nav {
		height: 100vh;
		padding: 25px 0;
		background-color: $c-primary;

		@include max-width($scr-sm) {
			background-size: 300px;
			height: calc(100vh - 50px);
		}

		&__header {
			display: flex;
			align-items: flex-end;

			@include max-width($scr-sm) {
				display: none;
			}
		}

		&__edit-button {
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__menu-button {
			width: 25px;
			height: 25px;
			padding: 0 3px;
			margin: 0 20px;
			background-color: transparent;
			cursor: pointer;
			border: 0;
			flex-shrink: 0;
		}

		&__logo {
			width: 150px;
			height: 50px;
			margin: 0 30px;
			background-size: contain;
			background-position: left;
			background-repeat: no-repeat;
			cursor: pointer;
			transition: $transition;

			@include max-width($scr-sm) {
				width: 0;
				margin: 0 15px;
			}
		}

		&__name {
			width: 100%;
			margin: 0 0 0 40px;
			color: $c-white;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
			transition: $transition;
			cursor: pointer;

			@include max-width($scr-sm) {
				margin: 15px 0 0 20px;
			}
		}

		&__headline {
			margin: 15px 0 15px 40px;
			color: $c-white;
			white-space: nowrap;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
			transition: $transition;

			@include max-width($scr-sm) {
				display: none;
				margin: 15px 0 0 20px;
			}
		}

		&__nav {
			position: relative;
			height: calc(100% - 60px);
			overflow-x: hidden;
			overflow-y: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			display: flex;
			flex-direction: column;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__list {
			margin: 0;
			padding: 0 0 0 40px;
			transition: $transition;

			@include max-width($scr-sm) {
				padding: 0 0 0 20px;
			}
		}

		&__item {
			position: relative;
			margin: 10px 0;
			width: 100%;
			min-width: 200px;
			cursor: pointer;
			z-index: 999;
			pointer-events: none;
			list-style-type: none;
			display: flex;
			flex-direction: column;
			justify-content: center;
			transition: $transition;

			&--logout {
				margin-top: auto;
				margin-bottom: 25px;
				padding: 0 0 25px 45px;

				@include max-width($scr-sm) {
					padding: 0 0 25px 20px;
				}
			}
		}

		&__wrapper {
			min-height: 50px;
			color: $c-white;
			text-decoration: none;
			white-space: nowrap;
			pointer-events: auto;
			display: flex;
			align-items: center;
		}

		&__content {
			display: flex;
			flex-direction: row;
			transition: $transition;
		}

		&__icon {
			width: 23px;
			height: 23px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;

			svg {
				width: 100%;
				height: 100%;
				flex-shrink: 0;
			}

			.dashboard-icon {
				width: 16px;
				height: 16px;
			}
		}

		&__children {
			width: 240px;
			margin: 15px 0;
			padding: 0 23px;
			pointer-events: auto;

			&:hover {
				.side-nav__label {
					color: lighten($c-green, 50%);
				}
			}
		}

		&__label {
			margin: 2.5px 0 0 10px;
			color: $c-white;
			pointer-events: none;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
			line-height: 1.3;
			white-space: pre;
			transition: $transition;

			&--active {
				color: $c-primary;
			}

			&--child-active {
				color: $c-green;
			}
		}

		&__background {
			position: absolute;
			height: 50px;
			width: 100%;
			top: 26px;
			right: 0;
			left: 25px;
			border-top-left-radius: 40px;
			border-bottom-left-radius: 40px;
			background-color: $c-background;
			transition: $transition;
			z-index: 888;

			&:before,
			&:after {
				content: "";
				position: absolute;
				background-color: transparent;
				right: 25px;
				height: 35px;
				width: 15px;
				transition: $transition;
			}

			&:before {
				bottom: -35px;
				border-top-right-radius: 15px;
				box-shadow: 0 -15px 0 0 $c-background;
			}

			&:after {
				top: -35px;
				border-bottom-right-radius: 15px;
				box-shadow: 0 15px 0 0 $c-background;
			}
		}
	}

	&--shrinked {
		width: $side-nav-shrinked-width;

		@include max-width($scr-sm) {
			width: 100%;

			.side-nav {
				&__logo {
					width: 100px;
				}

				&__header {
					display: flex;
				}

				&__headline {
					display: block;
				}

				&__background {
					left: 7px;

					&:before,
					&:after {
						height: 35px;
						width: 15px;
						right: 7px;
					}

					&:before {
						bottom: -35px;
					}

					&:after {
						top: -35px;
					}
				}
			}
		}

		@include min-width($scr-sm) {
			.side-nav {
				&__logo {
					margin: 0 -15px;
					transform: scale(0.65);
				}

				&__name {
					margin: 0 15px;
					white-space: nowrap;
				}

				&__menu-button {
					display: none;
				}

				&__headline {
					margin: 15px;
				}

				&__edit-button {
					display: none;
				}

				&__list {
					padding: 0 0 0 16px;
				}

				&__item {
					&--logout {
						padding: 0 0 0 18px;
					}
				}

				&__content {
					align-items: center;
				}

				&__label {
					opacity: 0;
				}

				&__background {
					left: 7px;

					&:before,
					&:after {
						height: 35px;
						width: 15px;
						right: 7px;
					}

					&:before {
						bottom: -35px;
					}

					&:after {
						top: -35px;
					}
				}
			}
		}
	}
}
