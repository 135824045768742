.page-setup-coverages {
	height: 100%;

	.setup-coverages {
		min-height: 100%;
		display: flex;
		flex-direction: column;

		&__header {
			padding: 0 35px 25px;
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 5px 0;
			color: $c-dark-grey;
		}

		&__plans {
			padding: 0 35px 25px;
			gap: 15px;
			display: flex;
			flex-wrap: wrap;
		}

		&__plan {
			border-radius: 8px;
			display: flex;
			align-items: center;

			&--premier {
				border: 1px solid #d2a741;
			}

			&--regular-plus {
				border: 1px solid #afafaf;
			}

			&--regular {
				border: 1px solid #c1a48a;
			}
		}

		&__wrapper {
			padding: 12.5px 20px;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
			display: flex;
			align-items: center;

			&--premier {
				background-color: #d2a741;
			}

			&--regular-plus {
				background-color: #afafaf;
			}

			&--regular {
				background-color: #c1a48a;
			}

			.insurance-plan {
				flex-shrink: 0;
			}
		}

		&__text {
			margin: 0 5px;
			color: $c-white;
			white-space: nowrap;
			font-size: $text-size-xs;
		}

		&__quantity {
			margin: 0;
			padding: 10px 20px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__total {
			margin: 0;
			padding: 10px 0;
			white-space: nowrap;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__filter {
			padding: 0 35px 20px;
			display: flex;
		}

		&__filter-text {
			margin: 0 20px 0 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__checkboxs {
			gap: 10px 20px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.app-checkbox {
				margin: 0 0 10px;
				&__box {
					border: 1px solid $c-light-grey;
				}
			}
		}

		&__label {
			margin: 0;
			padding: 5px 10px;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			border-radius: 100px;
			white-space: nowrap;

			&--premier {
				color: #d2a741;
				border: 1px solid #d2a741;
				background-color: #fff9ec;
			}

			&--regular-plus {
				color: #afafaf;
				border: 1px solid #afafaf;
				background-color: #f4f4f4;
			}

			&--regular {
				color: #c1a48a;
				border: 1px solid #c1a48a;
				background-color: #f9f2ec;
			}
		}

		&__footer {
			padding: 0 35px 25px;

			.app-button {
				width: 100%;
				max-width: 200px;
				margin-left: auto;
			}
		}

		.app-table {
			.table {
				&__note {
					cursor: pointer;

					&::before {
						width: 15px;
						height: 15px;
						margin: 0 5px;
						content: "";
						display: block;
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						background-image: url("../../../assets/images/exclamation-icon.svg");
						flex-shrink: 0;
					}
				}

				&__td {
					.app-select-input {
						max-width: 150px;

						&__label {
							display: none;
						}

						.MuiInputBase-root {
							padding: 8px;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						.MuiSelect-select {
							padding: 0;
							color: $c-dark-grey;
							font-size: $text-size-s;
							font-weight: $text-weight-regular;
						}

						.MuiOutlinedInput-notchedOutline {
							border-radius: 10px;
							border: 1px solid $c-light-grey !important;
						}

						.MuiFormHelperText-root {
							display: none;
						}
					}
				}
			}
		}
	}
}