.app-screen-loading {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 9999999;
	opacity: 0;
	transition: $transition;

	&--active {
		opacity: 1;
	}

	.screen-loading {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&__text {
			margin: 10px 0 0;
			color: $c-white;
			text-align: center;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
		}

		&__dot {
			margin: 0 0 5px;
			color: $c-white;
			text-align: center;
			letter-spacing: 5px;
			font-size: $text-size-xs;
			font-weight: $text-weight-bold;
		}

		&__icon {
			width: 55px;
			height: 55px;

			svg {
				width: 100%;
				height: 100%;

				path {
					stroke-dasharray: 500;
					animation: dash 5s linear infinite;
				}
			}
		}
	}
}

@keyframes dash {
	to {
		stroke-dashoffset: 1000;
	}
}
