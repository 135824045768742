.page-setup {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px 15px;
	}

	.setup {
		padding-bottom: 25px;

		&__headline {
			margin: 10px 0 0;
			color: $c-primary;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__note {
			margin: 5px 0 25px;
			color: $c-red;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 8px 0;
			color: $c-black;
			font-size: $text-size-s;
		}

		&__text {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__total {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__required {
			color: $c-red;
		}

		&__body {
			gap: 15px;
			display: flex;
			flex-direction: column;
		}

		&__card {
			padding: 25px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

			&--accordion {
				cursor: pointer;
			}

			&--active {
				border: 1px solid $c-green;

				.company-card__arrow {
					transform: rotate(-180deg);
				}
			}

			&--error {
				border: 1px solid $c-red;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 200px;
			margin-left: auto;
		}

		&__disclaimer {
			margin-top: 25px;
		}

		&__disclaimer-title {
			margin: 0 0 5px;
			color: #f64444;
			font-style: italic;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			display: flex;
			align-items: center;

			img {
				margin-right: 5px;
			}
		}

		&__disclaimer-list {
			padding: 0;
			margin: 0;
			list-style-type: none;
			font-style: italic;
			font-size: $text-size-s;
		}

		&__disclaimer-item {
			margin: 2.5px 0;
			font-style: italic;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__privacy-policies {
			margin: 20px 0 0;

			.app-checkbox {
				&__label {
					font-weight: $text-weight-regular;

					a {
						color: $c-blue;
					}
				}
			}
		}

		.company-card {
			display: flex;
			align-items: flex-start;

			@include max-width($scr-md) {
				flex-direction: column;
			}

			&__logo-placeholder {
				margin: 0;
				padding: 15px;
				color: $c-white;
				white-space: nowrap;
				background-color: $c-light-grey;
			}

			&__logo {
				width: 160px;
				height: 50px;
			}

			&__company-content {
				padding: 0 15px;

				@include max-width($scr-md) {
					padding: 10px 0;
				}
			}

			&__company-button {
				margin-left: auto;

				@include max-width($scr-md) {
					margin: 15px 0 0;
				}

				.app-button {
					padding: 10px 20px;
					flex-direction: row-reverse;

					.app-icon {
						width: 15px;
						height: 15px;
						margin: 0 0 0 5px;
					}
				}
			}

			&__body {
				overflow-x: scroll;
			}

			&__icon {
				width: 35px;
				height: 35px;
				border-radius: 100%;
				background-color: #f6fbff;
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;

				.user-management-icon,
				.app-file-icon {
					width: 20px;
					height: 20px;
				}
			}

			&__total-remaining {
				margin-left: auto;
				gap: 8px;
				display: flex;
				flex-direction: row;
				align-items: flex-start;
			}

			&__arrow {
				display: flex;

				.app-chevron-icon {
					width: 15px;
					height: 15px;
					cursor: pointer;
				}
			}

			&__list-header {
				margin: 15px 0 0;
				display: flex;
				align-items: center;
			}

			&__employees {
				margin-bottom: 10px;
				display: flex;
				justify-content: stretch;
			}

			&__input {
				position: relative;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
				background-color: #f8f8f8;
			}

			&__label {
				width: 25%;
				min-width: 130px;
				margin: 0;
				padding: 15px;
				color: $c-dark-grey;
				font-size: $text-size-xs;
				font-weight: $text-weight-semibold;
				flex: 1;
			}

			&__required {
				color: $c-red;
				font-size: $text-size-s;
				font-weight: $text-weight-normal;
			}

			&__actions {
				padding: 15px;
				background-color: #f8f8f8;
				flex: 1;
				display: flex;
				justify-content: flex-end;
			}

			&__remove-employee,
			&__edit-employee {
				border: 0;
				cursor: pointer;
				background-color: transparent;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&__text {
				width: 25%;
				min-width: 130px;
				margin: 0;
				padding: 15px;
				color: $c-dark-grey;
				word-break: break-word;
				font-size: $text-size-xs;
				background-color: #f8f8f8;
				flex: 1;
			}

			&__upload {
				margin: 0;
				color: $c-primary;
				font-size: $text-size-xs;
				background-color: #f8f8f8;
				font-weight: $text-weight-normal;
			}

			&__file-name {
				margin: 0;
				color: $c-primary;
				text-align: left;
				word-break: break-word;
				font-size: $text-size-xs;
				background-color: #f8f8f8;
			}

			&__upload-icon {
				margin: 0 8px;
			}

			&__download-icon {
				margin: 0 8px;
				transform: rotate(180deg);
			}

			&__download {
				min-width: 130px;
				display: flex;
				justify-content: flex-end;
			}

			&__remove-file-button {
				width: 33.33%;
				border: 0;
				padding: 15px;
				background-color: transparent;
				display: flex;
				align-items: center;
			}

			&__download-template-button {
				margin: 0;
				border: 0;
				color: $c-primary;
				font-size: $text-size-xs;
				background-color: transparent;
				cursor: pointer;
			}

			&__download-uploaded-button {
				min-width: 130px;
				width: 33.33%;
				margin: 0;
				padding: 15px;
				border: 0;
				background-color: transparent;
				cursor: pointer;
				display: flex;
				align-items: center;
			}

			&__upload-button {
				margin: 0;
				padding: 15px;
				text-align: left;
				color: $c-dark-grey;
				font-size: $text-size-xs;
				border: 0;
				background-color: transparent;
				cursor: pointer;
				display: flex;
				align-items: center;
				flex: 1;
			}

			&__progress-bar {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				width: 0;
				height: 2px;
				background-color: $c-green;
				transition: $transition;
			}

			&__add-employee-button {
				width: 100%;
				margin: 5px 0 0;
				padding: 15px 20px;
				text-align: left;
				color: $c-primary;
				font-size: $text-size-s;
				font-weight: $text-weight-normal;
				border-radius: 8px;
				border: 1px dashed #1a6ed3;
				background-color: transparent;
				cursor: pointer;
				display: flex;
				align-items: center;

				svg {
					margin: 0 5px;

					path {
						stroke: $c-primary;
					}
				}
			}
		}
	}
}
