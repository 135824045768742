.page-claim-approval {
	height: 100%;

	.claim-approval {
		height: 100%;
		display: flex;
		flex-direction: column;

		&__results {
			padding: 10px 0;
			margin: 10px 30px;
			border-bottom: 1px dashed $c-disabled;
		}

		&__text {
			margin: 0;
			font-weight: $text-weight-semibold;
		}

		&__empty-state {
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.app-empty-data {
				height: auto;
			}
		}

		.app-table {
			.table {
				&__amount {
					text-align: right;
					color: $c-dark-grey;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
				}
			}
		}
	}
}
