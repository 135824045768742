.page-dashboard {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px 15px;
	}

	.dashboard {
		padding-bottom: 25px;

		&__header {
			padding: 15px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include max-width($scr-md) {
				flex-direction: column;

				.dashboard__wrapper {
					margin: 20px 0 0 auto;
				}
			}

			.app-button {
				width: inherit;
				padding: 10px 20px;
				flex-direction: row-reverse;

				&__label {
					font-size: $text-size-xs;
				}

				svg {
					width: 20px;
					height: 20px;
					margin: 0 0 0 5px;
				}
			}
		}

		&__headline {
			margin: 10px 0 0;
			color: $c-primary;
			font-weight: $text-weight-normal;
		}

		&__title {
			margin: 0;
			color: $c-dark-grey;
			font-weight: $text-weight-normal;
		}

		&__description {
			margin: 5px 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__date {
			margin: 5px 0 25px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
		}

		&__icon {
			display: flex;
			align-items: center;
			cursor: pointer;

			svg {
				width: 15px;
				height: 15px;
				transform: rotate(-90deg);
			}
		}

		&__label {
			margin: 0 5px;
			color: $c-primary;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
		}

		&__views {
			display: flex;
			align-items: center;
		}

		&__text {
			margin: 0 5px;
			color: $c-primary;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
		}

		&__container {
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		&__wrapper {
			display: flex;
			align-items: center;
		}

		&__remaining {
			margin: 0 15px;
			color: $c-teal;
			white-space: nowrap;
			font-size: $text-size-s;

			span {
				color: $c-teal;
				font-weight: $text-weight-bold;
			}
		}

		&__section {
			gap: 15px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}

		.card {
			position: relative;
			max-width: calc(33.33% - 10px);
			padding: 25px;
			border-radius: 10px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			flex: 1 0 25%;
			display: flex;
			flex-direction: column;

			@include max-width($scr-md) {
				width: 100%;
				max-width: 100%;
				min-width: 350px;
			}

			&--total-employee {
				min-width: 500px;

				@include max-width($scr-sm) {
					min-width: 300px;
				}
			}

			&__total {
				position: absolute;
				top: 15px;
				right: 20px;
				margin: 0;
				color: $c-teal;
				font-size: $text-size-xxxl;
				font-weight: $text-weight-semibold;
			}

			&__header {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__link {
				text-decoration: none;
			}

			&__title {
				margin: 0;
				color: $c-black;
			}
		}

		.app-tabs {
			width: 100%;

			.tabs {
				margin: 0;
			}
		}

		.announcements {
			margin: 0;
			padding: 0;
			width: 100%;
			list-style-type: none;

			&__item {
				padding: 15px 0;
				border-bottom: 1px solid $c-disabled;
				display: flex;

				&:first-child {
					padding-top: 0;
				}
			}

			&__title {
				margin: 5px 0;
				color: $c-primary;
				font-size: $text-size-s;
			}

			&__description {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-xs;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			&__see-more {
				margin: 0;
				color: $c-blue;
				font-size: $text-size-xs;
				cursor: pointer;
				display: none;
			}

			&__status {
				margin-left: auto;
				padding: 0 15px;
				display: flex;
				align-items: center;
			}

			&__actions {
				min-width: 110px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}

			&__button {
				width: 25px;
				height: 25px;
				padding: 0;
				margin: 0 5px;
				background-color: transparent;
				border: 0;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;

				&--pdf {
				}

				&--pin {
				}

				&--pinned {
					img {
						padding: 3.5px;
					}
				}

				&--more {
					img {
						padding: 2.5px;
					}
				}

				img {
					width: 100%;
				}
			}
		}

		.celebrations {
			margin: 15px 0 0;
			padding: 0;
			width: 100%;
			list-style-type: none;

			&__item {
				padding: 5px 0;
				display: flex;

				&--celebration {
					align-items: center;
				}
			}

			&__profile {
				width: 28px;
				height: 28px;
				border-radius: 100%;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				background-color: $c-disabled;
				flex-shrink: 0;
			}

			&__content {
				padding: 0 10px;
			}

			&__name {
				margin: 0 0 5px;
				color: $c-dark-grey;
				font-size: $text-size-s;
				word-break: break-word;
				flex: 1;
			}

			&__state {
				margin: 0;
				color: $c-light-grey;
				font-size: $text-size-xs;
			}

			&__date {
				margin: 0 0 0 auto;
				color: $c-primary;
				font-size: $text-size-xs;
				font-weight: $text-weight-normal;
				text-align: right;
				flex: 1;
			}

			&__icon {
				width: 25px;
				height: 25px;
			}
		}

		.leaves {
			margin: 15px 0 0;
			padding: 0;
			width: 100%;
			list-style-type: none;

			&__item {
				padding: 5px 0;
				display: flex;
				align-items: center;
			}

			&__profile {
				width: 28px;
				height: 28px;
				border-radius: 100%;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				background-color: $c-disabled;
				flex-shrink: 0;
			}

			&__name {
				margin: 0 10px;
				color: $c-dark-grey;
				font-size: $text-size-s;
				word-break: break-word;
				flex: 1;
			}

			&__balance {
				margin: 0 0 0 auto;
				color: $c-red;
				font-size: $text-size-xs;
				font-weight: $text-weight-normal;
				text-align: right;
				flex: 1;
			}

			&__icon {
				width: 25px;
				height: 25px;
			}
		}

		.total-employee {
			position: relative;
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;

			&__chart {
				width: 100%;
				height: 250px;
			}

			&__legends {
				margin: 0;
				padding: 15px 0;
				list-style-type: none;
				display: flex;
				flex-wrap: wrap;
				align-self: center;
			}

			&__badge {
				width: 15px;
				height: 15px;
				margin: 8px;
				border-radius: 100%;
				background-color: #eaeaea;
				display: block;
			}

			&__legend {
				font-size: $text-size-xs;
				display: flex;
				align-items: center;
			}
		}
	}
}
