.page-employees {
	height: 100%;

	.employees {
		height: 100%;
		display: flex;
		flex-direction: column;

		&__empty-state {
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.app-empty-data {
				height: auto;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 250px;
			margin: 0 auto;
			gap: 15px;
			display: flex;
			flex-direction: row;
		}

		&__results {
			padding: 10px 0;
			margin: 10px 30px;
			border-bottom: 1px dashed $c-disabled;
		}

		&__text {
			margin: 0;
			font-weight: $text-weight-semibold;
		}

		.app-search-input {
			.app-button {
				// .app-icon {
				// 	width: 18px;
				// 	height: 18px;
				// }
			}
		}

		.app-table {
			//
			.table {
				//
				&__exclamation {
					width: 35px;
					height: 35px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}
