.app-upload-additional-quotation-documents-modal {
	margin: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	.upload-additional-quotation-documents {
		width: 100%;
		max-width: 560px;
		padding: 50px 35px;
		border-radius: 10px;
		background-color: $c-white;
		outline: none;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 10px 0 25px;
			color: $c-dark-grey;
		}

		&__note {
			margin: 0 0 15px;
			color: $c-red;
			font-size: $text-size-xs;
		}

		&__body {
			gap: 10px;
			display: flex;
			flex-direction: column;
		}

		&__list-header {
			display: flex;
			align-items: center;
		}

		&__label {
			min-width: 130px;
			margin: 0;
			padding: 15px;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
			flex: 1;

			&:nth-last-child(2) {
				width: 100%;
				max-width: 150px;
			}

			&:last-child {
				width: 50px;
				min-width: 50px;
				flex: 0;
			}
		}

		&__input {
			position: relative;
			display: flex;
			align-items: center;
			background-color: #f8f8f8;
		}

		&__text {
			margin: 0;
			padding: 15px;
			color: #13a7f4;
			word-break: break-all;
			font-size: $text-size-xs;
			flex: 1;

			&:nth-last-child(3) {
				width: 100%;
				max-width: 150px;
				color: $c-dark-grey;
			}
		}

		&__upload {
			margin: 0;
			color: $c-teal;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
		}

		&__delete {
			padding: 15px;
			width: 50px;
			text-align: center;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__upload-button {
			margin: 0;
			padding: 15px;
			text-align: left;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;
			flex: 1;

			svg {
				margin: 0 5px;

				path {
					stroke: $c-teal;
				}
			}
		}

		&__asterisk {
			color: $c-red;
		}

		&__progress-bar {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 0;
			height: 2px;
			background-color: $c-green;
			transition: $transition;
		}

		&__button-container {
			margin: 25px 0 0;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button {
				&--outline {
					border: 0;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
