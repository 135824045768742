.app-additional-documents-modal {
	min-width: 300px;
	padding: 10px;
	display: flex;

	.additional-documents-modal {
		width: 100%;
		max-width: 560px;
		margin: auto;
		padding: 50px;
		border-radius: 8px;
		background-color: $c-white;

		&__header {
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			color: $c-dark-grey;
		}

		&__input {
			display: flex;
			align-items: center;
			background-color: #f8f8f8;
		}

		&__section {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__upload-section {
			position: relative;
			margin: 0 0 10px;
			background-color: #f8f8f8;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__text {
			width: 50%;
			margin: 0;
			padding: 15px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__link {
			color: $c-blue;
			text-align: left;
			word-break: break-all;
			font-size: $text-size-s;
			text-decoration: none;
		}

		&__label-size {
			width: 50%;
			margin: 0;
			padding: 15px;
			text-align: right;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__name {
			margin: 0;
			padding: 15px;
			color: $c-blue;
			text-align: left;
			word-break: break-all;
			font-size: $text-size-s;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;
			flex: 1;
		}

		&__size {
			width: 50%;
			margin: 0;
			padding: 15px;
			text-align: right;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__upload {
			margin: 0;
			padding: 15px;
			color: $c-teal;
			text-align: left;
			font-size: $text-size-s;
			background-color: transparent;
			border: 0;
			cursor: pointer;
			display: flex;
			align-items: center;
			flex: 1;
		}

		&__icon {
			margin: 0 8px;
		}

		&__progress-bar {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 0;
			height: 2px;
			background-color: $c-green;
			transition: $transition;
		}

		&__footer {
			margin: 5px 15px;
			color: $c-red;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
		}

		&__button-container {
			margin: 25px 0 0;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button {
				&--outline {
					border: 0;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
