.app-insufficient-insurer-modal {
	min-width: 300px;
	padding: 10px;
	display: flex;

	.insufficient-insurer-modal {
		width: 100%;
		max-width: 560px;
		margin: auto;
		padding: 50px;
		border-radius: 8px;
		background-color: $c-white;

		&__header {
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			color: $c-dark-grey;
		}

		&__button-container {
			margin: 25px 0 0;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button--outline {
				border: 0;
			}
		}

		&__button {
			margin-top: 25px;
			border: 0;

			.app-button__label {
				color: $c-primary;
			}
		}
	}
}
