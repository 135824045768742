.app-policy-modal {
	min-width: 300px;

	.policy-modal {
		width: 100%;
		height: 100%;
		max-width: 560px;
		margin-left: auto;
		background-color: $c-white;

		.app-select-input {
			padding: 0 45px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}

			.MuiFormControl-root {
				padding: 0 15px;
				border-radius: 10px;
				background-color: #d3e0e8;

				.MuiSelect-select {
					color: $c-primary;
					font-weight: $text-weight-semibold;
				}

				.MuiFormHelperText-root {
					display: none;
				}

				.MuiSelect-icon {
					path {
						stroke: $c-primary;
					}
				}

				.MuiOutlinedInput-notchedOutline {
					border: 0 !important;
				}
			}
		}

		&__main {
			width: 100%;
			height: 100%;
			padding: 25px 0;
			display: flex;
			flex-direction: column;

			@include max-width($scr-xs) {
				overflow-y: scroll;
				scrollbar-width: none;
				-ms-overflow-style: none;

				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}
			}
		}

		&__header {
			padding: 0 45px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}
		}

		&__close-button {
			width: 30px;
			height: 30px;
			margin: 0 15px 15px auto;
			border: 0;
			cursor: pointer;
			background-color: transparent;
			display: block;
		}

		&__title {
			margin: 15px 0;
			color: $c-primary;
			font-weight: $text-weight-semibold;
		}

		&__amount {
			margin: 15px 0 5px;
			font-weight: $text-weight-semibold;
		}

		&__text {
			margin: 5px 0 0;
			color: $c-light-grey;
			word-wrap: break-word;
			font-size: $text-size-xs;
		}

		&__scrollable {
			padding: 0 0 15px;
			height: 100%;
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__body {
			padding: 15px 45px;

			&--activity {
				padding: 0 45px;
			}

			@include max-width($scr-xs) {
				height: auto;
				overflow: hidden;
				padding: 25px 35px;

				&--activity {
					padding: 0 45px;
				}
			}
		}

		&__container {
			display: flex;
			flex-wrap: wrap;
		}

		&__info {
			width: 50%;
			margin: 10px 0;
			padding: 0 15px 0 0;

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__label {
			margin: 5px 0;
			color: $c-light-grey;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__value {
			margin: 0;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__divider {
			margin: 0 45px;
			border: 1px dashed $c-disabled;
		}

		&__loading {
			height: calc(100% - 250px);
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 70px;
				height: 70px;

				path {
					stroke: #eaeaea;
					stroke-dasharray: 500;
					animation: loading 5s linear infinite;
				}
			}
		}

		.info {
			padding: 0 45px;
			margin: 0 0 25px;
			display: flex;
			align-items: stretch;
			gap: 10px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}

			&__box {
				flex: 1;
				padding: 20px 12.5px;
				background-color: #f8f8f8;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}
		}

		.policy-expired {
			width: 100%;
			padding: 0 45px;

			&__content {
				margin: 15px 0 25px;
				border-radius: 10px;
				border: 1px solid $c-disabled;
			}

			&__text {
				margin: 0;
				padding: 15px;
				color: $c-red;
				font-size: $text-size-xs;
			}
		}

		.policy-renewal {
			width: 100%;
			padding: 0 45px;

			&__header {
				margin-bottom: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.app-button {
					width: auto;
					padding: 10px 15px;
					display: flex;
					flex-direction: row-reverse;

					&__label {
						white-space: nowrap;
						font-size: $text-size-xs;
					}

					.app-icon {
						width: 15px;
						height: 15px;
						margin: 0 0 0 5px;
						transform: rotate(180deg);
					}
				}
			}

			&__list {
				margin: 15px 0 0;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__label {
				margin: 0;
				text-align: right;
				color: $c-dark-grey;
				font-size: $text-size-xs;
			}

			&__value {
				margin: 0;
				color: $c-black;
				font-size: $text-size-xs;
			}

			&__title {
				margin: 0;
				color: $c-black;
				font-weight: $text-weight-semibold;
			}

			&__content {
				padding: 15px;
				margin: 15px 0 25px;
				border-radius: 10px;
				border: 1px solid $c-disabled;
			}

			&__text {
				margin: 0 0 10px;
				color: $c-red;
				font-size: $text-size-xs;
			}

			&__quotation-no {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-s;
			}

			&__premium {
				margin: 0;
				font-size: $text-size-s;
			}

			&__quotations {
				padding: 0;
				margin: 5px 0 0;
			}

			&__quotation {
				margin: 0 0 10px;
				list-style-type: none;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__radio {
				display: flex;
				align-items: center;
			}

			&__radio-button {
				position: relative;
				width: 22px;
				height: 22px;
				margin: 0 8px 0 0;
				cursor: pointer;
				border-radius: 100px;
				background-color: $c-white;
				border: 2px solid $c-teal;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;

				&:disabled {
					opacity: 0.1;
				}

				&--active {
					&:before {
						content: "";
						display: block;
						position: absolute;
						width: 65%;
						height: 65%;
						border-radius: 100px;
						background-color: $c-teal;
						flex-shrink: 0;
					}
				}
			}

			&__button-container {
				margin: 0 0 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;
			}

			&__button {
				margin: 0 0 15px 10px;
				padding: 10px 25px;
				color: $c-dark-grey;
				font-size: $text-size-s;
				font-weight: $text-weight-normal;
				border-radius: 100px;
				background-color: $c-white;
				border: 1px solid $c-dark-grey;
				cursor: pointer;
				display: flex;
				align-items: center;

				&:disabled {
					color: $c-disabled;
					background-color: $c-white;
					border: 1px solid $c-disabled;

					svg {
						rect {
							fill: $c-disabled;
						}

						path {
							fill: $c-disabled;
							stroke: $c-disabled;
						}
					}
				}

				svg {
					width: 11px;
					height: 11px;
					margin: 0 0 0 5px;
				}

				&--revise {
					color: $c-green;
					border-color: $c-green;

					svg {
						width: 17px;
						height: 17px;
					}
				}

				&--reject {
					color: $c-red;
					border-color: $c-red;
				}

				&--accept {
					color: $c-white;
					border-color: $c-primary;
					background-color: $c-primary;

					svg {
						width: 13px;
						height: 13px;

						path {
							fill: transparent !important;
						}
					}
				}
			}
		}

		.additional-document {
			width: 100%;
			margin: 15px 0 25px;

			&__document {
				padding: 15px;
				border-radius: 10px;
				border: 1px solid $c-disabled;
			}

			&__header {
				margin-bottom: 15px;
				padding-bottom: 15px;
				border-bottom: 1px dashed #d3e0e8;
				gap: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__title {
				margin: 0;
				color: $c-dark-grey;
				font-weight: $text-weight-semibold;
			}

			&__content {
				gap: 10px;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}

			&__text {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-xs;
			}

			.app-button {
				width: auto;
				padding: 10px 15px;
				display: flex;
				flex-direction: row-reverse;

				&__label {
					white-space: nowrap;
					font-size: $text-size-xs;
				}

				svg {
					width: 15px;
					height: 15px;
					margin: 0 0 0 5px;
					path {
						stroke: $c-white;
					}
				}
			}
		}

		.plan {
			margin: 25px 45px;

			.app-button {
				width: auto;
				padding: 10px 15px;
				border-radius: 10px;
				align-self: flex-start;
				flex-direction: row-reverse;

				&__label {
					white-space: nowrap;
				}

				.app-icon {
					width: 18px;
					height: 18px;
					margin: 0 0 0 5px;
					transform: rotate(180deg);
				}

				.user-management-icon {
					margin: 0 0 0 5px;
				}
			}

			&__header {
				gap: 15px;
				display: flex;
				justify-content: space-between;

				@include max-width($scr-sm) {
					flex-direction: column;
				}
			}

			&__nav {
				display: flex;
				justify-content: flex-end;
			}

			&__title {
				margin: 0;
				color: $c-black;
				font-weight: $text-weight-semibold;
			}

			&__divider {
				border: 1px dashed $c-disabled;
			}
		}

		.credit-debit-note {
			//
			.app-select-input {
				max-width: 100px;
				margin: 15px auto;
				padding: 0 30px;

				.MuiFormControl-root {
					background-color: $c-white !important;

					.MuiSelect-select {
						padding-right: 0;
					}
				}
			}
		}

		.payment-history {
			height: 100%;

			.policy-modal__loading {
				height: calc(100% - 85px);
			}

			&__header {
				display: flex;
				align-items: center;
				justify-content: center;

				.app-select-input {
					max-width: 100px;
					min-width: auto;
					margin: 15px 0;
					padding: 0;

					.MuiFormControl-root {
						background-color: $c-white !important;

						.MuiSelect-select {
							padding-right: 0;
						}
					}
				}
			}

			&__type-select-input {
				max-width: 150px !important;
			}
		}

		.box {
			margin: 25px 45px 15px;

			&__container {
				margin: 20px 0;
				border-radius: 10px;
				border: 1px solid $c-disabled;
			}

			&__header {
				padding: 20px;
				display: flex;
				justify-content: space-between;
			}

			&__body {
				padding: 20px;
			}

			&__footer {
				padding: 0px 20px 20px;
			}

			&__content {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.app-button {
					width: auto;
					padding: 10px 15px;
					flex-direction: row-reverse;

					&:disabled {
						svg {
							path {
								stroke: #828a8f;
							}
						}
					}

					svg {
						width: 25px;
						height: 25px;
						margin: 0 0 0 5px;
					}
				}
			}

			&__insurer {
				margin: 0 0 15px;
				padding: 0 0 15px;
				gap: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				&:not(:first-child, :last-child) {
					border-bottom: 1px solid $c-disabled;
				}

				.app-button {
					width: auto;
					padding: 10px 15px;
					flex-direction: row-reverse;

					.app-icon {
						margin: 0 0 0 5px;
					}
				}
			}

			&__center {
				flex: 2;
			}

			&__divider {
				border: 1px dashed $c-disabled;
			}

			&__list {
				padding: 0;
				margin: 0;
			}

			&__coverage {
				padding: 0 15px;
				margin: 10px 0;
				gap: 15px;
				display: flex;
				justify-content: space-between;
			}

			&__avatar {
				width: 50px;
				height: 50px;
				border-radius: 50px;
				border: 2px solid $c-white;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			}

			&__title {
				margin: 0;
				color: $c-black;
				font-weight: $text-weight-semibold;
			}

			&__headline {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
			}

			&__sub-headline {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-s;
			}

			&__amount {
				margin: 0;
				color: $c-black;
				font-weight: $text-weight-semibold;
			}

			&__text {
				margin: 0;
				color: $c-light-grey;
				font-size: $text-size-xs;
			}

			&__benefit {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-xs;

				&:first-child {
					flex: 1;
					display: flex;

					&::before {
						content: "•";
						display: block;
						margin: 0 10px 0 0;
					}
				}
			}

			&__note {
				margin: 0;
				color: $c-red;
				font-size: $text-size-xs;
			}
		}

		.history {
			padding: 0 45px;

			&__header {
				padding: 5px 0 10px;
			}

			&__month {
				margin: 0;
				color: $c-black;
				font-weight: $text-weight-semibold;
			}

			&__checkpoint {
				position: absolute;
				top: 5px;
				left: -4.5px;
				display: block;
				content: "";
				width: 8px;
				height: 8px;
				border-radius: 8px;
				background: $c-green;
				z-index: 999999999999;
			}

			&__checkpoint-line {
				content: "";
				position: absolute;
				top: 18px;
				left: -1px;
				bottom: 0;
				width: 1px;
				background-color: $c-disabled;

				&--extend {
					top: 0;
				}
			}

			&__invoice-no {
				position: relative;
				margin: 0 0 5px;
				padding: 0 0 0 10px;
				color: $c-dark-grey;
				font-size: $text-size-s;
			}

			&__invoice-amount {
				position: relative;
				margin: 0 0 5px;
				padding: 0 0 0 10px;
				color: $c-dark-grey;
				font-size: $text-size-s;
			}

			&__date {
				margin: 0;
				padding: 5px 0 0 10px;
				color: $c-light-grey;
				font-size: $text-size-xs;
			}

			&__amount {
				margin: 0 0 5px;
				color: $c-black;
				text-align: right;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
			}

			&__container {
			}

			&__body {
				position: relative;
				padding: 2px 0 15px;
				display: flex;
			}

			&__start {
				flex: 2;
			}

			&__center {
				text-align: right;
			}

			&__end {
				text-align: right;
			}

			&__list {
				width: 100%;
				padding: 0;
				margin: 0;
			}

			&__item {
				margin: 0 0 10px;
				display: flex;
				justify-content: space-between;
			}

			&__download-button {
				width: 30px;
				height: 30px;
				border: 0;
				cursor: pointer;
				background-color: transparent;
				display: flex;
				justify-content: center;
			}

			&__download-icon {
				transform: rotate(180deg);
			}
		}
	}
}
