.app-invite-employee-modal {
	min-width: 300px;
	padding: 10px;
	display: flex;

	.invite-employee-modal {
		margin: auto;
		height: 100%;
		max-height: 100%;
		border-radius: 8px;
		background-color: $c-white;
		overflow: hidden;

		@include max-width($scr-md) {
			padding: 25px;
		}

		&__main {
			width: 100%;
			height: 100%;
			max-width: 1024px;
			padding: 50px;
			overflow-y: scroll;
		}

		&__header {
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 5px 0 20px;
			color: $c-dark-grey;
			white-space: pre;
		}

		&__body {
			gap: 20px 35px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.app-input,
			.app-mobile-input,
			.app-select-input,
			.app-button-input,
			.app-masking-input,
			.app-calendar-input {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}
		}

		&__wrapper {
			.app-select-input {
				width: 100%;
			}

			@include min-width($scr-sm) {
				width: calc(50% - 17.5px);
			}
		}

		&__link {
			margin: 2.5px 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;

			a {
				color: $c-blue;
				font-size: $text-size-xs;
			}
		}

		&__footer {
			width: 100%;
			margin: 25px 0 0;
			display: flex;
			gap: 15px;

			@include max-width($scr-sm) {
				flex-direction: column;
			}

			.app-checkbox {
				justify-content: flex-start;

				&__label {
					white-space: nowrap;
					font-weight: $text-weight-normal;
				}
			}
		}

		&__invited {
			margin: 0;
			color: $c-teal;
			white-space: nowrap;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			display: flex;
			align-items: center;

			img {
				margin: 0 5px 0 0;
			}
		}

		&__button-container {
			width: 50%;
			margin-left: auto;
			gap: 15px;
			display: flex;

			@include max-width($scr-sm) {
				width: 100%;
				margin: 15px 0 0;
				flex-wrap: wrap;
			}

			.app-button {
				width: 50%;

				@include max-width($scr-sm) {
					width: 100%;
				}

				&--outline {
					border: 0;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
