.app-checkbox {
	border: 0;
	padding: 0;
	background-color: transparent;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	&--active {
		.app-checkbox__box {
			background-color: $c-primary;
			border: 1px solid $c-primary;
		}
	}

	&--error {
		.app-checkbox__box {
			border: 1px solid $c-red;
		}
	}

	&__box {
		width: 20px;
		height: 20px;
		padding: 3px;
		border-radius: 4px;
		border: 1px solid $c-black;
		pointer-events: none;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		.app-icon {
			width: 100%;

			&__i {
				width: 100%;
			}
		}
	}

	&__uncheck-icon {
		width: 80%;
		height: 2.5px;
		border-radius: 100px;
		background-color: $c-primary;
	}

	&__label {
		margin: 0 8px;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}
}
