.app-create-announcement-modal {
	min-width: 300px;
	padding: 10px;
	display: flex;

	.create-announcement-modal {
		width: 100%;
		max-height: 100%;
		max-width: 1024px;
		margin: auto;
		padding: 50px;
		border-radius: 8px;
		background-color: $c-white;

		@include max-width($scr-md) {
			padding: 25px;
			overflow-y: scroll;
		}

		&__header {
		}

		&__title {
			margin: 10px 0 20px;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 10px 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__body {
			gap: 20px 35px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.app-input,
			.app-select-input,
			.app-calendar-input {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}

			.app-input {
				&--multiline {
					width: 100%;
				}
			}
		}

		&__upload-input {
			width: 100%;
		}

		&__input {
			width: 100%;
			display: flex;
			align-items: center;
			background-color: #f8f8f8;
		}

		&__label {
			margin: 0;
			padding: 15px;
			text-align: right;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			flex: 1;
		}

		&__upload-button {
			margin: 0;
			padding: 15px;
			text-align: left;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;
			flex: 1;
		}

		&__error {
			width: 100%;
			margin: 10px 0 0;
			color: $c-red;
			font-weight: $text-weight-normal;
			font-size: $text-size-xs;
		}

		&__button-container {
			width: 50%;
			margin-left: auto;
			gap: 15px;
			display: flex;

			@include max-width($scr-sm) {
				width: 100%;
				margin: 15px 0 0;
				flex-wrap: wrap;
			}

			.app-button {
				width: 50%;

				@include max-width($scr-sm) {
					width: 100%;
				}

				&--outline {
					border: 0;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
