.app-upload-company-profile-modal {
	margin: 15px;
	display: flex;
	align-items: center;
	justify-content: center;

	.upload-company-profile {
		width: 100%;
		max-width: 560px;
		padding: 35px;
		border-radius: 10px;
		background-color: $c-white;
		outline: none;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&__header {
			margin: 0 0 15px;
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 10px 0;
			color: $c-dark-grey;
		}

		&__note {
			padding-top: 20px;
			margin-top: 20px;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			border-top: 1px dashed $c-disabled;
		}

		&__input {
			display: flex;
			align-items: center;
			background-color: #f8f8f8;
		}

		&__label {
			margin: 0;
			padding: 15px;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			flex: 1;
		}

		&__upload-button {
			margin: 0;
			padding: 15px;
			text-align: left;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;
			flex: 1;
		}

		&__icon {
			margin: 0 8px;
		}

		&__footer {
			margin: 5px 15px;
			color: $c-red;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
		}

		&__button-container {
			margin: 35px 0 0;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button {
				&--outline {
					border: 0;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
