.page-upload-claim-documents {
	height: 100%;
	padding: 0 35px;

	@include max-width($scr-md) {
		padding: 0 15px 15px;
	}

	.upload-claim-documents {
		padding-bottom: 25px;

		&__card {
			margin: 25px 0;
			padding: 25px 0;
			border-radius: 10px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(182, 189, 194, 0.15);
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			gap: 15px;
		}

		&__section {
			width: 100%;
			margin-bottom: 25px;
			padding: 0 25px 25px;
			border-bottom: 1px dashed $c-disabled;
		}

		&__section-button {
			margin: 25px 0 0;

			.app-button {
				width: 100%;
				max-width: 200px;
				align-items: center;
				flex-direction: row-reverse;

				.app-icon {
					margin: 0 5px;
					width: 18px;
					height: 18px;
				}
			}
		}

		&__title {
			margin: 0;
			color: $c-black;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 10px 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__list {
			padding: 0;
		}

		&__item {
			list-style-type: none;
		}

		&__body {
			gap: 10px;
			display: flex;
			flex-direction: column;
		}

		&__list-header {
			display: flex;
			align-items: center;
		}

		&__label {
			min-width: 130px;
			margin: 0;
			padding: 15px;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
			flex: 1;

			&:nth-last-child(2) {
				width: 100%;
				max-width: 150px;
			}

			&:last-child {
				width: 50px;
				min-width: 50px;
				flex: 0;
			}
		}

		&__asterisk {
			color: $c-red;
		}

		&__input {
			position: relative;
			display: flex;
			align-items: center;
			background-color: #f8f8f8;
		}

		&__text {
			margin: 0;
			padding: 15px;
			color: #13a7f4;
			font-size: $text-size-xs;
			flex: 1;

			&:nth-last-child(2) {
				width: 100%;
				max-width: 150px;
				color: $c-dark-grey;
			}
		}

		&__upload {
			margin: 0;
			color: $c-teal;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
		}

		&__delete {
			padding: 15px;
			width: 50px;
			text-align: center;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__upload-button {
			margin: 0;
			padding: 15px;
			text-align: left;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;
			flex: 1;

			svg {
				margin: 0 5px;

				path {
					stroke: $c-teal;
				}
			}
		}

		&__progress-bar {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 0;
			height: 2px;
			background-color: $c-green;
			transition: $transition;
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin-left: auto;
			gap: 15px;
			display: flex;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
