.app-order-modal {
	min-width: 300px;

	.order-modal {
		width: 100%;
		height: 100%;
		max-width: 560px;
		margin-left: auto;
		background-color: $c-white;

		&__main {
			width: 100%;
			height: 100%;
			padding: 25px 0;

			@include max-width($scr-xs) {
				overflow-y: scroll;
				scrollbar-width: none;
				-ms-overflow-style: none;

				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}
			}
		}

		&__header {
			padding: 0 45px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}
		}

		&__close-button {
			width: 30px;
			height: 30px;
			margin: 0 15px 15px auto;
			border: 0;
			cursor: pointer;
			background-color: transparent;
			display: block;
		}

		&__title {
			margin: 15px 0 5px;
			color: $c-primary;
			font-weight: $text-weight-semibold;
		}

		&__amount {
			margin: 15px 0 5px;
			font-weight: $text-weight-semibold;
		}

		&__date {
			margin: 0 0 15px;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__text {
			margin: 5px 0 0;
			color: $c-light-grey;
			word-wrap: break-word;
			font-size: $text-size-xs;
		}

		&__scrollable {
			padding: 0 0 15px;
			height: calc(100% - 200px);
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__body {
			padding: 15px 45px;

			&--activity {
				padding: 0 45px;
			}

			@include max-width($scr-xs) {
				height: auto;
				overflow: hidden;
				padding: 25px 35px;

				&--activity {
					padding: 0 45px;
				}
			}
		}

		&__container {
			margin: 0 0 15px;
			padding: 0 0 15px;
			border-bottom: 1px dashed $c-disabled;
			display: flex;
			flex-wrap: wrap;
		}

		&__info {
			width: 50%;
			margin: 10px 0;
			padding: 0 15px 0 0;

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__label {
			margin: 5px 0;
			color: $c-light-grey;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__value {
			margin: 0;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__headline {
			margin: 0;
			color: $c-black;
			font-weight: $text-weight-semibold;
		}

		&__headline-wrapper {
			margin: 0 0 15px;
		}

		&__wrapper {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__section {
			flex: 1;
			margin: 8px 0;
			white-space: pre;
			color: $c-dark-grey;
			word-wrap: break-word;
			font-size: $text-size-s;

			&:nth-child(even) {
				text-align: right;
			}
		}

		.additional-document {
			width: 100%;
			padding: 0 45px;
			margin: 15px 0 25px;

			&__document {
				padding: 15px;
				border-radius: 10px;
				border: 1px solid $c-disabled;
			}

			&__header {
				margin-bottom: 15px;
				padding-bottom: 15px;
				border-bottom: 1px dashed #d3e0e8;
				gap: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__title {
				margin: 0;
				color: $c-dark-grey;
				font-weight: $text-weight-semibold;
			}

			&__content {
				gap: 10px;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}

			&__text {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-xs;
			}

			.app-button {
				width: auto;
				padding: 10px 15px;
				display: flex;
				flex-direction: row-reverse;

				&__label {
					white-space: nowrap;
					font-size: $text-size-xs;
				}

				.app-icon {
					width: 15px;
					height: 15px;
					margin: 0 0 0 5px;
				}
			}
		}

		.info {
			padding: 0 45px;
			margin: 0 0 25px;
			display: flex;
			align-items: stretch;
			gap: 10px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}

			&__box {
				flex: 1;
				padding: 20px 12.5px;
				background-color: #f8f8f8;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}
		}

		.plans {
			width: 100%;
			padding: 15px;
			margin: 0 0 15px;
			border-radius: 10px;
			border: 1px solid $c-disabled;

			&__header {
				margin-bottom: 15px;
				padding-bottom: 15px;
				border-bottom: 1px dashed $c-disabled;
				gap: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__plan {
				margin: 0;
				color: $c-dark-grey;
				font-weight: $text-weight-semibold;
			}

			&__label {
				margin: 0;
				color: $c-dark-grey;
			}

			&__price {
				margin: 0;
				text-align: right;
				color: $c-dark-grey;
				white-space: nowrap;
			}

			&__item {
				gap: 15px;
				display: flex;
				justify-content: space-between;
			}

			&__sub-item {
				padding: 0;
			}

			&__benefits {
				margin: 0 0 5px;
				gap: 15px;
				display: flex;
				justify-content: space-between;
			}

			&__benefit {
				width: 100%;
				max-width: 70%;
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-xs;
			}

			&__coverage-amount {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-s;
			}

			&__benefit-amount {
				margin: 0;
				text-align: right;
				white-space: nowrap;
				color: $c-light-grey;
				font-size: $text-size-s;
			}

			&__benefit-amount-gtl {
				margin: 0;
				text-align: right;
				white-space: nowrap;
				color: $c-dark-grey;
				font-size: $text-size-xs;
			}
		}

		.plan {
			margin: 25px 0;

			&__header {
				gap: 15px;
				display: flex;
				justify-content: space-between;

				@include max-width($scr-sm) {
					flex-direction: column;
				}
			}

			&__title {
				margin: 0;
				color: $c-black;
				font-weight: $text-weight-semibold;
			}

			&__divider {
				border: 1px dashed $c-disabled;
			}
		}

		.box {
			margin: 25px 0 15px;

			&__container {
				margin: 20px 0;
				border-radius: 10px;
				border: 1px solid $c-disabled;
			}

			&__header {
				padding: 20px;
				display: flex;
				justify-content: space-between;
			}

			&__body {
				padding: 20px;
			}

			&__insurer {
				margin: 0 0 15px;
				padding: 0 0 15px;
				gap: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				&:not(:first-child, :last-child) {
					border-bottom: 1px solid $c-disabled;
				}
			}

			&__center {
				flex: 2;
			}

			&__divider {
				border: 1px dashed $c-disabled;
			}

			&__list {
				padding: 0;
				margin: 0;
			}

			&__avatar {
				width: 50px;
				height: 50px;
				border-radius: 50px;
				border: 2px solid $c-white;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			}

			&__title {
				margin: 0;
				color: $c-black;
				font-weight: $text-weight-semibold;
			}

			&__headline {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
			}

			&__sub-headline {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-s;
			}

			&__text {
				margin: 0;
				color: $c-light-grey;
				font-size: $text-size-xs;
			}
		}

		.files {
			width: 100%;

			&__header {
				display: flex;
				align-items: center;
			}

			&__label {
				width: 33.33%;
				margin: 0;
				padding: 10px 15px 10px 10px;
				color: $c-dark-grey;
				word-wrap: break-word;
				font-size: $text-size-xs;
				font-weight: $text-weight-semibold;

				+ .files__label {
					padding: 10px 15px 10px 0;
				}
			}

			&__text {
				width: 33.33%;
				margin: 0;
				padding-right: 15px;
				color: $c-dark-grey;
				word-wrap: break-word;
				font-size: $text-size-xs;

				&:first-child {
					display: flex;

					&:before {
						content: "";
						width: 15px;
						height: 15px;
						margin: 0 5px 0 0;
						background-size: contain;
						background-repeat: no-repeat;
						background-image: url(../../../../assets/images/components/pages/orders/activation-icon.svg);
						display: block;
						flex-shrink: 0;
					}
				}
			}

			&__name {
				width: 33.33%;
				margin: 0;
				padding-right: 15px;
			}

			&__link {
				text-decoration: none;
				color: $c-blue;
				word-wrap: break-word;
				font-size: $text-size-xs;
				cursor: pointer;
			}

			&__body {
				width: 100%;
				padding: 0;
			}

			&__item {
				margin: 0 0 10px;
				padding: 15px 10px;
				background-color: #f8f8f8;
				list-style-type: none;
				display: flex;
				align-items: center;
			}
		}
	}
}
