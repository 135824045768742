.page-claim {
	height: 100%;

	.claim {
		height: 100%;
		display: flex;
		flex-direction: column;

		&__header {
			margin: 0 30px 5px;
			display: flex;
			align-items: center;

			@include max-width($scr-sm) {
				margin: 0 15px;
			}
		}

		&__name {
			position: relative;
			margin: 0 15px 0 0;
			padding: 0 15px 0 0;
			color: $c-black;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;

			&:after {
				position: absolute;
				top: 0;
				right: 0;
				content: "";
				display: block;
				height: 100%;
				width: 1px;
				border: 1px solid #e6e6e6;
			}
		}

		&__employee-id {
			margin: 0 15px 0 0;
			color: $c-black;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		.app-tabs {
			margin: 0 35px;

			@include max-width($scr-sm) {
				margin: 0 15px;
			}
		}

		.app-table {
			.table {
				&__td {
					&--action {
						padding: 0 20px 0 0;
					}
				}

				&__th,
				&__td {
					&--action {
						width: auto;
						max-width: inherit;
						text-align: right;
					}
				}

				&__th {
					&--buttons {
						padding: 10px 15px;
					}

					span {
						gap: 15px;
						display: flex;
						align-items: center;
						justify-content: flex-end;
					}
				}

				&__sort {
					border: 0;
					padding: 0;
					opacity: 0.2;
					background-color: transparent;
					transition: $transition;
					cursor: pointer;

					&--active {
						opacity: 1;
					}

					&:hover {
						opacity: 1;
					}

					img {
						width: 12.5px;
						height: 12.5px;
						margin: 0 5px;
						vertical-align: middle;
						pointer-events: none;
					}
				}
			}
		}
	}
}
