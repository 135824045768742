.app-insurance-claim-modal {
	min-width: 300px;

	.insurance-claim-modal {
		width: 100%;
		height: 100%;
		max-width: 560px;
		margin-left: auto;
		background-color: $c-white;

		&__main {
			width: 100%;
			height: 100%;
			padding: 25px 0;
			display: flex;
			flex-direction: column;

			@include max-width($scr-xs) {
				overflow-y: scroll;
				scrollbar-width: none;
				-ms-overflow-style: none;

				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}
			}
		}

		&__header {
			padding: 0 45px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}
		}

		&__profile {
			display: flex;
			justify-content: space-between;
		}

		&__close-button {
			width: 30px;
			height: 30px;
			margin: 0 15px 15px auto;
			border: 0;
			cursor: pointer;
			background-color: transparent;
			display: block;
		}

		.info {
			padding: 0 45px;
			margin: 0 0 25px;
			display: flex;
			align-items: stretch;
			gap: 10px;

			@include max-width($scr-xs) {
				padding: 0 35px;
			}

			&__box {
				flex: 1;
				padding: 20px 12.5px;
				background-color: #f8f8f8;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}
		}

		&__body {
			height: calc(100% - 250px);
			padding: 25px 45px;
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			@include max-width($scr-xs) {
				padding: 25px 35px;
			}
		}

		&__title {
			margin: 15px 0;
			font-weight: $text-weight-semibold;
		}

		&__description {
			width: 50%;
			margin: 15px 0 0 10px;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			text-align: right;
		}

		&__wrapper {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}

		&__info {
			width: 50%;
			margin: 10px 0;
			padding: 0 15px 0 0;

			@include max-width($scr-xs) {
				width: 100%;
			}

			&:last-child {
				width: 100%;
			}
		}

		&__label {
			margin: 0 0 5px;
			color: $c-light-grey;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__value {
			margin: 0;
			font-size: $text-size-s;
			word-wrap: break-word;
		}

		&__link {
			color: $c-blue;
			font-size: $text-size-s;
			display: flex;
			align-items: center;
		}

		&__text {
			margin: 5px 0 0;
			color: $c-light-grey;
			word-wrap: break-word;
			font-size: $text-size-xs;
		}

		&__docs-header {
			padding: 0 35px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.insurance-claim-modal__label {
				width: 33%;
				color: $c-dark-grey;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;

				&:last-child {
					width: 15%;
				}
			}
		}

		&__documents {
			padding: 0;
		}

		&__item {
			padding: 15px 35px;
			list-style-type: none;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:nth-child(odd) {
				background-color: #f8f8f8;
			}

			.insurance-claim-modal__label {
				padding: 0 10px 0 0;

				&:last-child {
					width: 15%;
				}
			}
		}

		.additional-document {
			width: 100%;
			padding: 0 45px;
			margin: 15px 0 25px;

			&__document {
				padding: 15px;
				border-radius: 10px;
				border: 1px solid $c-disabled;
			}

			&__header {
				margin-bottom: 15px;
				padding-bottom: 15px;
				border-bottom: 1px dashed #d3e0e8;
				gap: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__title {
				margin: 0;
				color: $c-dark-grey;
				font-weight: $text-weight-semibold;
			}

			&__content {
				gap: 10px;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}

			&__text {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-xs;
			}

			.app-button {
				width: auto;
				padding: 10px 15px;
				display: flex;
				flex-direction: row-reverse;

				&__label {
					white-space: nowrap;
					font-size: $text-size-xs;
				}

				svg {
					width: 15px;
					height: 15px;
					margin: 0 0 0 5px;
					path {
						stroke: $c-white;
					}
				}
			}
		}
	}
}
