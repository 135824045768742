.app-claim-history {
	height: 100%;
	display: flex;
	flex-direction: column;

	.app-table {
		.table {
			&__amount {
				text-align: right;
				color: $c-dark-grey;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
			}

			&__attachment {
				padding: 0 15px 0 0;
				text-align: center;
				color: $c-dark-grey;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
			}
		}
	}
}
