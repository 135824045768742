.app-button {
	width: 100%;
	border: 0;
	padding: 15px 20px;
	border-radius: 100px;
	background-color: $c-primary;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	&:disabled {
		border: 1px solid $c-disabled;
		background-color: $c-disabled;

		.app-button__label {
			color: #828a8f !important;
		}
	}

	&--outline {
		background-color: $c-white;
		border: 1px solid $c-primary;

		.app-button__label {
			color: $c-primary;
		}
	}

	&__label {
		margin: 0;
		color: $c-white;
		font-weight: $text-weight-normal;
		font-size: $text-size-s;
	}
}
